
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptions} from "@/Screens.types";
import Countries from "@hotosm/iso-countries-languages"
import Slider from "@/components/elements/Slider.vue";

const state = useState();
export default defineComponent({
  components: {
    Slider,
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
    }
  },

  computed: {
    countries() {
      return Countries.getCountries('en')
    },
    selectedCountry() {
      return this.state.answers.value?.[this.config.answerKeys?.country];
    },
    selectedGender() {
      return this.state.answers.value?.[this.config.answerKeys.gender];
    },
    selectedAge() {
      return this.state.answers.value?.[this.config.answerKeys.age];
    },
    selected() {
      return this.state.answers.value?.[this.config.answerKey];
    }
  },

  mounted() {
  },

  methods: {
    next() {
      const answer = state.answers.value?.[this.config.answerKey];
      const nextScreen = this.config.optionsFlow?.[answer] || this.config.screenNext;

      this.goTo(nextScreen)
    },
    back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string | number, answerKey, texts: object) {
      state.setAnswer(answerKey, answer, {
        ...texts
      });
      // this.next();
    }
  }
});
