
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenBringBack} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenBringBack
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      response: '',
    }
  },

  mounted() {
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext);
    },

    saveAndNext(next) {
      if (this.response.length > 0) {
        this.answer(this.response);
      }

      this.goTo(next);
    },
    answer(answer: string | number) {
      state.setAnswer(this.config.answerKey, answer, {
        [this.config.answerKey]: this.TR(this.config.question)
      });
      // this.next();
    }
  }
});
