export enum ScreensEnum {
    FinalScreen = 'FinalScreen',
    GOGRASS_INTRO = 'GOGRASS_INTRO',
    FindEcosystemServices = 'FindEcosystemServices',
    HowMuchEUCoveredGrasslands = 'HowMuchEUCoveredGrasslands',
    HowMuchEUCoveredGrasslandsAns = 'HowMuchEUCoveredGrasslandsAns',
    HowMuchGrasslandsConverted = 'HowMuchGrasslandsConverted',
    HowMuchGrasslandsConvertedAns = 'HowMuchGrasslandsConvertedAns',
    WhatShouldBeDone = 'WhatShouldBeDone',
    WhatShouldBeDoneAns = 'WhatShouldBeDoneAns',
    HowMuchDoYouImplementPractices = 'HowMuchDoYouImplementPractices',
    HaveYouEverBoughtAlternative = 'HaveYouEverBoughtAlternative',
    HaveYouEverBoughtAlternativeChart = 'HaveYouEverBoughtAlternativeChart',
    AlternativeWhatInformationPersuadesYou = 'AlternativeWhatInformationPersuadesYou',
    NewlyDevelopedProducts = 'NewlyDevelopedProducts',
    EndExperience = 'EndExperience',
    GrassBasedProteinPuzzle = 'GrassBasedProteinPuzzle',
    RoadsideGrassPuzzle = 'RoadsideGrassPuzzle',
    WhichInfoToBuyGrassProtein = 'WhichInfoToBuyGrassProtein',
    WhatWouldEncourageYouToBuyGrassProtein = 'WhatWouldEncourageYouToBuyGrassProtein',
    WhichInfoImportantRoadsideGrass = 'WhichInfoImportantRoadsideGrass',
    WhatWouldEncourageYouToBuyGrassPackaging = 'WhatWouldEncourageYouToBuyGrassPackaging',
    AnimalBeddingPuzzle = 'AnimalBeddingPuzzle',
    FertilizerPuzzle = 'FertilizerPuzzle',
    WhatOtherHorseBedding = 'WhatOtherHorseBedding',
    AnimalBeddingOpinions = 'AnimalBeddingOpinions',
    GrassProteinThankYou    = 'GrassProteinThankYou',
    GrassBiocharPuzzle = 'GrassBiocharPuzzle',
    WhereDoYouGetVegetables = 'WhereDoYouGetVegetables',
    RoadsideGrassThankYou = 'RoadsideGrassThankYou',
    AnimalBeddingThankYou = 'AnimalBeddingThankYou',
    BiocharGrowingOpinions = 'BiocharGrowingOpinions',
    BiocharThankYou = 'BiocharThankYou',
}


export enum ScreensTypeEnum {
    SCREEN_INTRO = 'screen-intro',
    SCREEN_OPTIONS_GREEN = 'screen-options-green',
    SCREEN_OPTIONS_GREEN_TOGGLE_QUESTION = 'screen-options-green-toggle-question',
    SCREEN_ANSWER_GREEN = 'screen-answer-green',
    SCREEN_ANSWER_GREEN_WITH_HIGH_LOW = 'screen-answer-green-with-high-low',
    SCREEN_OPTIONS = 'screen-options',
    SCREEN_ANSWER = 'screen-answer',
    SCREEN_ANSWERS_TWO_OPTIONS = 'screen-answers-two-options',
    SCREEN_OPTIONS_COUNTRIES = 'screen-options-countries',
    SCREEN_OPTIONS_BUTTONS = 'screen-options-buttons',
    SCREEN_OPTIONS_CIRCLES = 'screen-options-circles',
    SCREEN_DEMOGRAPHICS_CHART = 'screen-demographics-chart',
    SCREEN_OPTIONS_DRAGDROP = 'screen-options-dragdrop',
    SCREEN_WASTE_CAROUSEL = 'screen-waste-carousel',
    SCREEN_BEFORE_SHARE = 'screen-before-share',
    SCREEN_BRING_BACK = 'screen-bring-back',
    SCREEN_VIDEO = 'screen-video',
    SCREEN_FINAL = 'screen-final',
    SCREEN_SLIDERS = 'screen-slider-pink',
    SCREEN_QUESTION_TWO_BUTTONS = 'screen-question-two-buttons',
    SCREEN_SORT_OPTIONS = 'screen-sort-options',
    SCREEN_TEXTS = 'screen-tree-texts',
    SCREEN_SLIDER_PINK_MULTI = 'screen-slider-pink-multi',
    SCREEN_OPTIONS_RADIO_BUTTONS = 'screen-options-radio-buttons',
    SCREEN_SINGLE_QUESTION = 'screen-single-question',
    SCREEN_RATE_US = 'screen-rate-us',
    SCREEN_OPTIONS_MULTIPLE = 'screen-options-multiple',
    SCREEN_OPTIONS_TRUE_FALSE = 'screen-options-true-false',
}
