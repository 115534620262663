import {ScreensEnum} from "@/Screens.enum";

export default {
    "screen": {
        "welcome": "Willkommen!",
        "lets-start": "Lassen Sie uns beginnen...",
        "devices": "Versuchen Sie, zu raten",
        "video": "Was sind SE?",
        "map": "Wem gehören SE?",
        "alternative-supply": "Unsere Alternativen",
        "get-magnets": "Das Magnetlabor",
        "magnets-structure": "Legierungsherstellung",
        "magnets-process": "Letzter Schritt",
        "close-loop": "Den Kreis schliessen!",
        "challenges": "Drei Herausforderungen",
        "feedback": "Ihr Feedback!",
        "action-time": "Zeit für Action",
        "guess": "Können Sie erraten, welche dieser Produkte Legierungen oder Magnete aus Seltenen Erden enthalten?"
    },
    "eu-footer": 'Dieses Projekt wurde mit Mitteln des Programms Horizont 2020 unter der Finanzhilfevereinbarung Nr. 101003906 gefördert. Der Inhalt dieser Website gibt ausschließlich die Meinung des Autors wieder. Die Europäische Kommission kann nicht für die Verwendung der darin enthaltenen Informationen verantwortlich gemacht werden.',
    "buttons": {
        "submit": "Einreichen",
        "yes": "JA",
        "no": "NEIN",
        'next': 'Weiter',
        'back': 'Zurück',
        "start": "Los geht's! {name}!",
        "go": "Los geht's! {name}!",
        "see": "Los sehen!",
        "letsfindout": "Lass uns herausfinden!",
    },

    'texts': {
        'or': 'oder',
        'and': 'und',
        "read less": "Weniger lesen",
        "read more": "Mehr lesen",
    },

    'defaultNickname': 'Gast',

    "intro": {
        "nickname": "Nickname",
        "par1": `Willkommen bei der interaktiven SCIRT-Erfahrung. Dieses Instrument soll das Engagement fördern und die Auswirkungen der Modeindustrie auf die Umwelt und die Gesellschaft beleuchten. Ihr wertvoller Beitrag wird uns helfen, Ideen zu entwickeln, das Bewusstsein zu schärfen und Veränderungen in der Textilindustrie und im Verbraucherverhalten voranzutreiben.`,
        "par3": 'Sind Sie bereit, mitzumachen? Geben Sie Ihren Namen ein und klicken Sie auf "Los geht\'s".',
        "beforeButton": 'Ihre Daten werden anonymisiert und nur für Forschungszwecke verwendet. <br>NUR das SCIRT-Konsortium und die Forscher haben Zugang zu Ihren Daten. ',
    },
};
