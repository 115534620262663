
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigOptions} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigOptions,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
    }
  },

  mounted() {
  },

  computed: {
    optionsWithAnswersDone() {
      if (!this.config.markIfAnswerExists) {
        return {};
      }

      return Object.entries(this.config.markIfAnswerExists)
          .reduce((acc, [key, value]: [string, string]) => {
            if (this.state.answers.value?.[value]) {
              acc[key] = true;
            }
            return acc;
          }, {});
    }
  },

  methods: {
    next() {
      const answer = state.answers.value?.[this.config.answerKey];
      const nextScreen = this.config.optionsFlow?.[answer] || this.config.screenNext;

      this.goTo(nextScreen)
    },
    back() {
      this.goTo(-1)
    },
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    answer(answer: string | number, texts: object) {
      state.setAnswer(this.config.answerKey, answer, {
        [this.config.answerKey]: this.$t(this.config.textPart1 ?? this.config.title),
        ...texts
      });
      this.next();
    }
  }
});
