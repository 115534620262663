import {createApp} from 'vue'
import App from './App.vue'
import VueNextSelect from 'vue-next-select'
import i18n from './i18n'
import VueUniversalModal from 'vue-universal-modal'
import CountryFlag from 'vue-country-flag-next'
import ActionButton from "@/components/elements/ActionButton.vue";
import BackNextButtons from "@/components/elements/BackNextButtons.vue";
import $ from 'jquery'
import {polyfill} from "mobile-drag-drop";
import {scrollBehaviourDragImageTranslateOverride} from "mobile-drag-drop/scroll-behaviour";

const app = createApp(App)

// app.mixin(audioPlayerMixin)

app.use(i18n)
app.use(VueUniversalModal, {
    teleportTarget: '#modals'
})
app.component('VueSelect', VueNextSelect)
app.component('CountryFlag', CountryFlag)
app.component('ActionButton', ActionButton)
app.component('BackNextButtons', BackNextButtons)

window["$"] = $;
window["app"] = app;

app.mixin({
    methods: {
        TR(key: string, params: any = {}) {
            if (key.startsWith('@@')) {
                return this.$rt(key.substring(2), params)
            }

            if (key.indexOf(' ') !== -1) {
                return this.$rt(key, params)
            }

            return this.$t(key, params)
        }
    }
})

// options are optional ;)
polyfill({
    // use this to make use of the scroll behaviour
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    holdToDrag: 300,
});

export default app;
