
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigIntro} from "@/Screens.types";

const state = useState();
export default defineComponent({
  components: {
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigIntro,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false
    }
  },

  mounted() {
    // if local, dont reset
    if (window.location.hostname === 'localhost') return;

    this.state.resetState();
  },

  methods: {
    start() {
      // Open modal for nicname
      this.showNicknameModal = true;
    },
    next() {
      if (!this.state.nickname.value) {
        this.state.setNickname(this.$t('defaultNickname') as string)
      }

      this.goTo(this.config.screenNext)
    },
  }
});
