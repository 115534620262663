import {ScreensEnum} from "@/Screens.enum";
import {ScreenDefinitions} from "@/Screens.types";
import {markRaw} from "vue";
import GoGrassScreenIntro from "@/components/screens/GoGrassScreenIntro.vue";
import GoGrassImageMapScreen from "@/components/screens/GoGrassImageMapScreen.vue";
import ScreenQuestionButtonsBP from "@/components/screens/ScreenQuestionButtonsBP.vue";
import ScreenAnswerGG from "@/components/screens/ScreenAnswerGG.vue";
import ScreenDemoWithQuestionGG from "@/components/screens/ScreenDemoWithQuestionGG.vue";
import ScreenChartGG from "@/components/screens/ScreenChartGG.vue";
import ScreenQuestionMultiSlider from "@/components/screens/ScreenQuestionMultiSlider.vue";
import ScreenTextsGreen from "@/components/screens/ScreenTextsGreen.vue";
import ScreenSliderPuzzle from "@/components/screens/ScreenSliderPuzzle.vue";
import ScreenSingleQuestion from "@/components/screens/ScreenSingleQuestion.vue";
import ScreenSelectImages from "@/components/screens/ScreenSelectImages.vue";
import ScreenEndGG from "@/components/screens/ScreenEndGG.vue";

export const getScreens = ($t, state): ScreenDefinitions => {
    return {
        [ScreensEnum.AlternativeWhatInformationPersuadesYou]: {
            screenId: ScreensEnum.AlternativeWhatInformationPersuadesYou,
            screenNext: ScreensEnum.NewlyDevelopedProducts,
            title: '',
            questions: {
                question1: 'The product is more climate friendly.',
                question2: 'The product is easily recyclable.',
                question3: 'The production of this product emits less CO2 emission.',
                question4: 'The processes and products take animal welfare into consideration.',
                question5: "It is better for the environment as raw material is gathered in a way to not harm the environment.",
                question6: "The product is a healthier alternative for you.",
                question7: "A lot of people are using it.",
                question8: "The quality is very good.",
                question9: "The product is unique.",
                question10: "The product is completely locally made.",
                question11: "The production is transparent and there is no greenwashing.",
                question12: "The product is easily accessible.",
                question13: "The product has a reasonable price.",
            },
            answerKey: 'AlternativeWhatInformationPersuadesYou',
            answerUnit: '',
            additionalOptionsText: 'Other',
            textPart1: "{name} before we tell you more about our innovative products, we would really like to know one more thing!",
            textPart2: "When or if you decide to buy a product made from alternative sources, what information persuades you?",
            // textPart3: 'ShoppingHabits.text3',
            sliderSubtext: '1 Least important - 5 Most important',
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            // additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            bgImage: "img/gograss/sliders.jpg",
            bgStyles: 'background: none;',
            classes: {},
            component: markRaw(ScreenQuestionMultiSlider),
        },


        [ScreensEnum.GOGRASS_INTRO]: {
            component: markRaw(GoGrassScreenIntro),
            screenId: ScreensEnum.GOGRASS_INTRO,
            title: 'GOGRASS_INTRO.title',
            answerKey: 'GOGRASS_INTRO',
            textPart1: "This tool is designed to shed light on innovative technologies and products from an overlooked but easily accessible source – grass! Through this experience, we will take you on a small journey at the end of which, both you and us will be richer with new information.",
            textPart2: "Your valuable input will help raise awareness and drive change for a better tomorrow.",
            textPart3: 'Ready to start? Enter your nickname and click \'Start\' now!',
            textPart4: 'data-disclaimer',
            screenNext: ScreensEnum.FindEcosystemServices,
            bgImage: "/img/gograss/grass.jpg",
        },
        [ScreensEnum.FindEcosystemServices]: {
            component: markRaw(GoGrassImageMapScreen),
            screenId: ScreensEnum.FindEcosystemServices,
            screenNext: ScreensEnum.HowMuchEUCoveredGrasslands,
            title: '',
            answerKey: 'GOGRASS_INTRO',
            textPart1: "Dear {name}, grassland is an important type of land use in Europe, covering a large area and providing different ecosystem services.",
            textPart2: "Can you find the three ecosystem services that grassland is providing? You can choose between the options provided on the right.",
            // textPart3: 'Ready to start? Enter your nickname and click \'Start\' now!',
            // textPart4: 'data-disclaimer',
            image1: "/img/gograss/snippets1.png",
            imageMap: [
                [270, 46, 517, 152],
                [110, 183, 278, 322],
                [381, 204, 656, 308],
                [388, 343, 662, 471],
                [81, 374, 361, 498],
                [91, 554, 316, 663],
                [387, 528, 639, 655],
                [425, 691, 624, 860],
                [180, 714, 335, 862]
            ],
            popupTexts: [
                'Good game {name}!',
                'Grasslands is actually providing all of the ecosystem services that are shown in the background!',
                "Grasslands are ideal habitats for vast diversity of species and a vital breeding ground for birds and invertebrates. Grasslands are among the most species rich habitats on Earth!"
            ]
        },


        [ScreensEnum.HaveYouEverBoughtAlternative]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.HaveYouEverBoughtAlternative,
            screenNext: ScreensEnum.HaveYouEverBoughtAlternativeChart,
            title: "@@Great {name}, now please tell us if you have ever bought or used products made from alternative sources?",
            question: '',
            answerKey: 'HaveYouEverBoughtAlternative',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                yes: 'HaveYouEverBoughtAlternative.yes',
                no: 'HaveYouEverBoughtAlternative.no',
                notsure: 'HaveYouEverBoughtAlternative.notsure',
                prefertosticktoknown: 'HaveYouEverBoughtAlternative.prefertosticktoknown',
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
                rightSide: 'bg-white',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/sources.jpg) no-repeat center center; background-size: contain;',
        },

        [ScreensEnum.HaveYouEverBoughtAlternativeChart]: {
            component: markRaw(ScreenChartGG),
            screenId: ScreensEnum.HaveYouEverBoughtAlternativeChart,
            screenNext: ScreensEnum.AlternativeWhatInformationPersuadesYou,
            title: '',
            textPart1: "Thank you for your openness {name}, the information you provided will greatly help us moving forward with our research! ",
            textPart2: 'This is how you compare with others in your age group!',
            answerKey: 'HaveYouEverBoughtAlternative',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            chartLabelsPrefix: 'HaveYouEverBoughtAlternative',
            chartSeries: [
                {name: 'Which age group', filter: 'WhichAgeGroup', prefix: 'WhichAgeGroup'},
                // {name: 'Which gender', filter: 'WhichGender', prefix: 'WhichGender'},
            ],
            chartLabel: 'Age group: {WhichAgeGroup}',
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
                rightSide: 'bg-white',
            },
        },

        [ScreensEnum.HowMuchDoYouImplementPractices]: {
            component: markRaw(ScreenDemoWithQuestionGG),
            screenId: ScreensEnum.HowMuchDoYouImplementPractices,
            screenNext: ScreensEnum.HaveYouEverBoughtAlternative,
            title: "{name}, where are you from?",
            answerKey: 'HowMuchDoYouImplementPractices',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            question: "How much do you implement environmentally friendly practices in your life on a daily basis (e.g. cycling or using public transport, recycling, sorting waste, saving electricity, buying more sustainable products etc.)",
            answerOptions: {
                notatall: 'Not at all',
                'hereandthere': 'Here and there',
                'sometimes': 'Sometimes',
                'regularlyenopugh': 'Regularly enough',
                'allthetime': 'All the time',
            },
            answerUnit: '',
            options: {
                gender: {
                    'woman': 'Woman',
                    'man': 'Man',
                    'idontidentify': 'I don\'t identify with any gender',
                    'idrathernotsay': 'I\'d rather not say',
                },
                age: {
                    'under-18': 'Under 18 years',
                    '18-30': '18 - 30 years',
                    '31-40': '31 - 40 years',
                    '41-60': '41 - 60 years',
                    '60+': 'Above 60 years',
                }
            },
            answerKeys: {
                country: 'WhichCountry',
                gender: 'WhichGender',
                age: 'WhichAgeGroup',
            },
            classes: {},
            // bgImage: '#',
            // bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/gif_3.gif) no-repeat center center; background-size: cover;',
        },
        [ScreensEnum.HowMuchEUCoveredGrasslands]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.HowMuchEUCoveredGrasslands,
            screenNext: ScreensEnum.HowMuchEUCoveredGrasslandsAns,
            title: "Can you guess how much of the EU's total surface was covered in grassland in 2018?",
            question: '',
            answerKey: 'HowMuchEUCoveredGrasslands',
            source: {
                text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
                link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            },
            options: {
                5: '5%',
                17: '17%',
                35: '35%',
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/gif_1.gif) no-repeat center center; background-size: cover;',
        },

        [ScreensEnum.HowMuchEUCoveredGrasslandsAns]: {
            component: markRaw(ScreenAnswerGG),
            screenId: ScreensEnum.HowMuchEUCoveredGrasslandsAns,
            screenNext: ScreensEnum.HowMuchGrasslandsConverted,
            title: "",
            question: '',
            answerKey: 'HowMuchEUCoveredGrasslands',
            correctAnswer: 17,
            ansOkText: 'Correct! Around 17% of the EU\'s total surface area was covered by grassland in 2018!',
            textAnsTooLow: "You underestimated it this time, EU´s total surface grassland coverage is actually 17%.",
            textAnsTooHigh: "You overestimated it this time, EU´s total surface grassland coverage is actually 17%.",
            textPart1: "This abundant resource is often left unused, creating costs for society and for rural areas. Continue with the experience and find out what can be done!",
            readMore: {
                text1: "Only looking at the agricultural EU area, permanent grassland cover 34% of it!",
                text2: "Permanent grassland or permanent pasture is “land used to grow grasses or other herbaceous forage naturally (self-seeded) or through cultivation (sown) and are vital for human well-being as they contribute to a wide variety of essential ecosystem services, just as the ones you saw before.",
            },
            bgImage: "/img/gograss/surface17.png",
            bgStyles: 'background: white; padding: 3rem;',
            classes: {
                wrapperClasses: 'bg-project text-white p-0-important',
                rightSide: 'bg-white',
            },
        },

        [ScreensEnum.HowMuchGrasslandsConverted]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.HowMuchEUCoveredGrasslands,
            screenNext: ScreensEnum.HowMuchGrasslandsConvertedAns,
            title: "Unfortunately, permanent grassland have decreased in the last decades in Europe. Can you guess how many km² were converted into urban areas from 2012 to 2018?",
            question: '',
            answerKey: 'HowMuchGrasslandsConverted',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                560: '560 km²',
                1800: '1800 km²',
                2600: '2600 km²',
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/gif_2.gif) no-repeat center center; background-size: cover;',
        },

        [ScreensEnum.HowMuchGrasslandsConvertedAns]: {
            component: markRaw(ScreenAnswerGG),
            screenId: ScreensEnum.HowMuchGrasslandsConvertedAns,
            screenNext: ScreensEnum.WhatShouldBeDone,
            title: "",
            question: '',
            answerKey: 'HowMuchGrasslandsConverted',
            correctAnswer: 2600,
            ansOkText: "Correct! 2600 km² were converted into urban areas in the last period.",
            ansWrongText: "You underestimated it this time, 2600 km² were converted into urban areas in the last period.",
            textPart1: "To put it into perspective, that is more than the size of Luxembourg which has a population of 640.064 people and quite a lot more than the island Mauritius which is home to 1.235.260 people!",
            textPart2: "Though small in relative terms, this loss is large in absolute terms, particularly for grassland ecosystems.",
            readMore: {
                text1: "You can read more about what is happening to grassland since 2018 in the document by EEB and Birdlife International: Grasslands in the new CAP: bad news for biodiversity and climate.",
                image: "/img/gograss/luxpopup.jpg",
            },
            bgImage: "/img/gograss/luxemburg.png",
            bgStyles: 'background: white; padding: 1rem;',
            classes: {
                wrapperClasses: 'bg-project text-white p-0-important',
                rightSide: 'bg-white',
                bgImageClasses: 'max-w-xs'
            },
        },


        [ScreensEnum.NewlyDevelopedProducts]: {
            screenId: ScreensEnum.NewlyDevelopedProducts,
            screenNext: ScreensEnum.GrassBasedProteinPuzzle,
            answerKey: 'NewlyDevelopedProducts',
            component: markRaw(ScreenTextsGreen),
            title: '',
            textPart1: "This and even more is what you can expect from the new products being developed in the GO-GRASS project!",
            textPart2: "GO-GRASS is a Horizon 2020 project which is developing circular and sustainable technologies and business models with high replication potential that can be used by a variety of actors to <b>enable effective use of grassland</b>, which would otherwise be left to decay after mowing, causing costs and lost benefits for individuals and society.",
            textPart3: "The newly developed grass-based products are:",
            image1: "/img/gograss/products.png",
            bgImage: "url('img/gograss/grass2.png')",
            bgStyles: 'background-position: center bottom; background-size: 90%;',
            classes: {
                wrapperClasses: 'font-light',
            },
            image1Map: [
                {
                    "next": ScreensEnum.AnimalBeddingPuzzle,
                    "coords": [391, 234, 33, 67],
                },
                {
                    "next": ScreensEnum.FertilizerPuzzle,
                    "coords": [402, 33, 654, 274],
                },
                {
                    "next": ScreensEnum.RoadsideGrassPuzzle,
                    "coords": [681, 1, 1064, 112],
                },
                {
                    "next": ScreensEnum.GrassBasedProteinPuzzle,
                    "coords": [687, 161, 1077, 308],
                }
            ]
        },


        [ScreensEnum.WhatShouldBeDone]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.WhatShouldBeDone,
            screenNext: ScreensEnum.WhatShouldBeDoneAns,
            title: "{name}, what do you think should be done with the remaining grasslands in Europe?",
            question: '',
            answerKey: 'WhatShouldBeDone',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                management: 'Grassland management and valorisation',
                leavinguntouched: 'Leaving the grasslands untouched',
                'croplands': 'Turning grasslands into croplands',
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/gif_3.gif) no-repeat center center; background-size: cover;',
        },

        [ScreensEnum.WhatShouldBeDoneAns]: {
            component: markRaw(ScreenAnswerGG),
            screenId: ScreensEnum.WhatShouldBeDoneAns,
            screenNext: ScreensEnum.HowMuchDoYouImplementPractices,
            title: "",
            question: '',
            answerKey: 'WhatShouldBeDone',
            correctAnswer: 'management',
            ansOkText: "Spot on! Grassland management and valorisation is the correct answer!",
            ansWrongText: "Unfortunately, the decline of permanent grasslands poses significant ecological threats such as an increased nutrient leaching resulting from intense fertilisation after converting grasslands into croplands. Additionally, the grasslands being unmanaged and left as they are results in raised fire risk during dry summers.",
            textPart1: "Taking care of grasslands is really important, otherwise they might get turned into croplands to generate more money. Changing grasslands into fields affects soil in a way that it releases carbon and other gases into the air.",
            textPart2: "On the other hand, if we don´t manage the grasslands at all, this results in loss of different plants and small animal species, making the abandoned grasslands more prone to wildfires, as well as releasing a large amount of greenhouse gases.",
            textPart3: "Valorisation of grasslands through the development of new grass-based products could help these systems by restoring their environmental associated benefits.",
            textPart4: '{name} let´s take a closer look on how this can be done!',
            readMore: {
                text1: "Another factor that contributes massively to the decline of local and global biodiversity is the increase of land use intensity <br><small>(Blüthgen et al., 2016).</small>",
                text2: "A special form of land use intensity is the continuous increase in the amount and toxicity of fertilizers and pesticides used in agriculture and forestry <br><small>(Habel et al., 2019; Mineau and Whiteside, 2013; Sánchez-Bayoa and Wyckhuys, 2019; Tilman et al., 2001).</small>",
            },
            bgImage: "/img/gograss/grasseu.png",
            bgAnswerImages: {
                management: "/img/gograss/grasseu.png",
                leavinguntouched: "/img/gograss/grasseub.png",
                'croplands': "/img/gograss/grasseuc.png",
            },
            bgStyles: 'background: white; padding: 1rem;',
            classes: {
                wrapperClasses: 'bg-project text-white p-0-important',
                rightSide: 'bg-white',
            },
        },

        /// GRASSPROTEIN
        [ScreensEnum.GrassBasedProteinPuzzle]: {
            screenId: ScreensEnum.GrassBasedProteinPuzzle,
            screenNext: ScreensEnum.WhichInfoToBuyGrassProtein,
            answerKey: 'GrassBasedProteinPuzzle',
            component: markRaw(ScreenSliderPuzzle),
            title: "{name} solve this puzzle and find out why the grass-based protein started being developed!",
            popupTexts: [
                "Soy production, especially in major soy-exporting countries like Brazil and Argentina, has been linked to deforestation and land use changes. Large-scale soy cultivation often involves clearing forests, which can lead to the loss of biodiversity, disruption of ecosystems, and contribute to climate change.",
                "A significant portion of the soy imported into the EU is used for animal feed in livestock farming because of its high protein value.",
                "EU started looking at viable solutions and an unlikely source has a huge potential – GRASS!"
            ],
            image1: "/img/gograss/pigspuzzle.jpg",
            bgImage: "url('img/gograss/tagprotein.png')",
            bgStyles: 'background-position: center top; background-size: 100%;',
        },
        [ScreensEnum.WhichInfoToBuyGrassProtein]: {
            screenId: ScreensEnum.WhichInfoToBuyGrassProtein,
            screenNext: ScreensEnum.WhatWouldEncourageYouToBuyGrassProtein,
            title: '',
            questions: {
                question1: 'It needs to taste good.',
                question2: 'It needs to have a nice texture.',
                question3: 'It should be used in premade food (chocolate bars, snacks, etc).',
                question4: 'The processes and products take animal welfare into consideration.',
                question5: "It should be available as a food supplement (powder).",
                question6: "It should have a reasonable price.",
                question7: "The packaging should contain information on how it is helping the environment.",
                question8: "The packaging should contain recipes on how and where to use it.",
                question9: "The packaging should contain information on the health benefits.",
            },
            answerKey: 'WhichInfoToBuyGrassProtein',
            answerUnit: '',
            additionalOptionsText: 'Other',
            additionalOptionsPlaceholder: 'Please type your answer',
            textPart1: "As the developments are progressing, grass protein has shown properties that would make it a good protein source for humans as well.",
            textPart2: "Humans get most their protein from meat, but “nickname” we are interested, what information would you need in order to buy the grass-based protein?",
            // textPart3: 'ShoppingHabits.text3',
            sliderSubtext: '1 Not important - 5 Very important',
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            // additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            bgImage: "img/gograss/bowlprotein.jpg",
            bgStyles: 'background: none; ',
            classes: {},
            component: markRaw(ScreenQuestionMultiSlider),
        },
        [ScreensEnum.WhatWouldEncourageYouToBuyGrassProtein]: {
            screenId: ScreensEnum.WhatWouldEncourageYouToBuyGrassProtein,
            screenNext: ScreensEnum.GrassProteinThankYou,
            answerKey: 'WhatWouldEncourageYouToBuyGrassProtein',
            component: markRaw(ScreenSingleQuestion),
            title: "",
            question: `What would encourage you to try out a grass-based protein supplement for humans?`,
            textPart1: "Would you prefer it as a stand alone product or as part of another product (i.e. chocolate bar, drink, etc)?",
            bgImage: "img/gograss/grassbottles.jpg",
        },
        [ScreensEnum.GrassProteinThankYou]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.GrassProteinThankYou,
            screenNext: ScreensEnum.RoadsideGrassPuzzle,
            title: "@@Thank you for sharing your valuable opinion {name}.",
            question: "Since we are progressing so well, we'd like to invite you to also learn about the other interesting grass products!",
            answerKey: ScreensEnum.GrassProteinThankYou,
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                roadsidegrass: "Sure, I'd like to know more about paper made from roadside grass.",
                animalbedding: 'Ok, I am interested in the animal bedding.',
                grassbiochar: "Tell me more about the grass-based biochar.",
                allgood: "All good, I think I learned enough for today.",
            },
            markIfAnswerExists: {
                grassprotein: ScreensEnum.GrassProteinThankYou,
                roadsidegrass: ScreensEnum.RoadsideGrassThankYou,
                animalbedding: ScreensEnum.AnimalBeddingThankYou,
                grassbiochar: ScreensEnum.BiocharThankYou,
            },
            optionsFlow: {
                roadsidegrass: ScreensEnum.RoadsideGrassPuzzle,
                animalbedding: ScreensEnum.AnimalBeddingPuzzle,
                grassbiochar: ScreensEnum.GrassBiocharPuzzle,
                allgood: ScreensEnum.EndExperience,
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/proteintygrass.png) no-repeat center bottom; background-size: 100%;',
        },

        /// ROADSIDE
        [ScreensEnum.RoadsideGrassPuzzle]: {
            screenId: ScreensEnum.RoadsideGrassPuzzle,
            screenNext: ScreensEnum.WhichInfoImportantRoadsideGrass,
            answerKey: 'RoadsideGrassPuzzle',
            component: markRaw(ScreenSliderPuzzle),
            title: "{name} solve this puzzle to get the answer why the paper from roadside grass started being developed!",
            popupTexts: [
                "Deforestation is a significant environmental issue, and the production of paper is one of the contributing factors. The number of trees needed for a ton of paper can vary widely depending on factors such as the type of tree, the paper grade, and the pulping process. On average, it's often estimated that about 24 trees are needed to produce one ton of non-recycled printing and office paper.",
                "To solve the problem, paper is already being made from many alternative sources, but GO-GRASS developed a technology that is enabling paper being made from roadside grass! This grass is too dirty to be used for anything else, so most of the time it was mowed and left to rot, but now it has become one of potential solutions in fighting against deforestation.",
            ],
            image1: "/img/gograss/roadpuzzle.jpg",
            bgImage: "url('img/gograss/tagroadside.png')",
            bgStyles: 'background-position: center top; background-size: 75%;',
        },
        [ScreensEnum.WhichInfoImportantRoadsideGrass]: {
            screenId: ScreensEnum.WhichInfoImportantRoadsideGrass,
            screenNext: ScreensEnum.WhatWouldEncourageYouToBuyGrassPackaging,
            title: '',
            questions: {
                sameproperties: "The grass paper should have the same properties as the wood-based paper.",
                differentcolors: 'It should be available in different colours, including white.',
                fibers: "The paper should have grass fibers visible to make it look more organic.",
                visiblefibers: 'The paper should not have grass fibers visible.',
                organiccolors: "The paper should be coloured with organic colours.",
                reasonableprice: "The paper should be available at a reasonable price.",
                woodbased: "The paper should be just as durable as wood-based paper.",
                packaging: "The packaging should contain information on how it is helping the environment.",
                howtouse: "The packaging should contain information of how to use it (DIY, painting, drawing, etc).",
                handling: "The paper should be handled jus as easily as the wood based paper (folding, cutting).",
                available: "The paper should be available in multiple stores.",
            },
            answerKey: 'WhichInfoToBuyGrassProtein',
            answerUnit: '',
            additionalOptionsText: 'Other',
            additionalOptionsPlaceholder: 'Please type your answer',
            textPart1: "We think this is a very interesting solution! Now we'd love to hear your input on this grass paper!",
            textPart2: "Please evaluate the below points on a scale from least to most important.",
            // textPart3: 'ShoppingHabits.text3',
            sliderSubtext: '1 Not important - 5 Very important',
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            // additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            bgImage: "img/gograss/roadsidepaper.png",
            bgStyles: 'background: none;',
            classes: {},
            component: markRaw(ScreenQuestionMultiSlider),
        },
        [ScreensEnum.WhatWouldEncourageYouToBuyGrassPackaging]: {
            screenId: ScreensEnum.WhatWouldEncourageYouToBuyGrassPackaging,
            screenNext: ScreensEnum.RoadsideGrassThankYou,
            answerKey: 'WhatWouldEncourageYouToBuyGrassPackaging',
            component: markRaw(ScreenSingleQuestion),
            title: "",
            question: `@@{name} thanks for all the answers!`,
            textPart1: "What would encourage you personally to use paper or packaging made from grass?",
            bgImage: "img/gograss/grasspackaging.jpg",
        },
        [ScreensEnum.RoadsideGrassThankYou]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.RoadsideGrassThankYou,
            screenNext: ScreensEnum.AnimalBeddingPuzzle,
            title: "@@Awesome {name}, let us suggest another grass product to learn about!.",
            answerKey: ScreensEnum.RoadsideGrassThankYou,
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                grassprotein: "Grass protein",
                animalbedding: 'Ok, I am interested in the animal bedding.',
                grassbiochar: "Tell me more about the grass-based biochar.",
                allgood: "All good, I think I learned enough for today.",
            },
            markIfAnswerExists: {
                grassprotein: ScreensEnum.GrassProteinThankYou,
                roadsidegrass: ScreensEnum.RoadsideGrassThankYou,
                animalbedding: ScreensEnum.AnimalBeddingThankYou,
                grassbiochar: ScreensEnum.BiocharThankYou,
            },
            optionsFlow: {
                grassprotein: ScreensEnum.GrassBasedProteinPuzzle,
                animalbedding: ScreensEnum.AnimalBeddingPuzzle,
                grassbiochar: ScreensEnum.GrassBiocharPuzzle,
                allgood: ScreensEnum.EndExperience,
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/grassroadside.png) no-repeat center bottom; background-size: 100%;',
        },

        // ANIMAL BEDDING
        [ScreensEnum.AnimalBeddingPuzzle]: {
            screenId: ScreensEnum.AnimalBeddingPuzzle,
            screenNext: ScreensEnum.WhatOtherHorseBedding,
            answerKey: 'AnimalBeddingPuzzle',
            component: markRaw(ScreenSliderPuzzle),
            title: "{name} solve this puzzle to get the answer for whom the enhanced animal bedding was developed and find out why this is no ordinary bedding.",
            popupTexts: [
                "Reed Canary Grass (RCG) is shredded and pressed into briquettes – an innovative material for animal bedding which afterwards can easily be used as fertiliser, as well as for biogas or heat production.",
                "RCG is a much more suitable source for animal bedding as the materials used so far – sawdust and wood shavings – hold more potential for the use in biorefinery processes or material development. Furthermore, RCG bedding with manure will result in higher efficiency of the biogas process and contribute to increase circularity."
            ],
            image1: "/img/gograss/horsepuzzle.jpg",
            bgImage: "url('img/gograss/taganimalbedding.png')",
            bgStyles: 'background-position: center top; background-size: 60%;',
        },
        [ScreensEnum.WhatOtherHorseBedding]: {
            screenId: ScreensEnum.WhatOtherHorseBedding,
            screenNext: ScreensEnum.AnimalBeddingOpinions,
            answerKey: 'NewlyDevelopedProducts',
            component: markRaw(ScreenSelectImages),
            title: '',
            textPart1: "Now that we know more about the enhanced organic grass bedding, what other purpose could it be used for, other than as horse stable bedding?",
            classes: {
                mainClasses: 'sm:col-span-8',
                textsWrapper: 'sm:w-1/2',
            },
            images: {
                'mouse': '/img/gograss/imgbedding1.png',
                'pig': '/img/gograss/imgbedding2.png',
                'pieces': '/img/gograss/imgbedding3.png',
                'guineapig': '/img/gograss/imgbedding4.png',
                'carrots': '/img/gograss/imgbedding5.png',
                'hamsters': '/img/gograss/imgbedding6.png',
                'horses': '/img/gograss/imgbedding7.png',
                'chickens': '/img/gograss/imgbedding8.png',
                'cows': '/img/gograss/imgbedding9.png'
            }
        },
        [ScreensEnum.AnimalBeddingOpinions]: {
            screenId: ScreensEnum.AnimalBeddingOpinions,
            screenNext: ScreensEnum.AnimalBeddingThankYou,
            title: '',
            questions: {
                comparableprice: 'Comparable price',
                consumption: 'Consumption per week',
                storage: 'Storage in non-dry environments',
                animalwellbeing: 'Animal wellbeing',
                freshsmell: "Fresh smell",
                easilyavailable: "Easily available",
                homedelivery: "Home delivery",
                absorptioncapacity: "Absorption capacity",
                dustfree: "Dust-free",
                'Easy to muck': "Easy to muck",
                'Easy to handle': "Easy to handle",
                'Locally made': "Locally made",
                'Environmentally friendly': "Environmentally friendly",
                'Easily disposable': "Easily disposable",
                'Sustainable packaging': "Sustainable packaging",
            },
            answerKey: 'AnimalBeddingOpinions',
            answerUnit: '',
            additionalOptionsText: 'Other',
            additionalOptionsPlaceholder: 'Please type your answer',
            textPart1: "This surely is a multipurpose animal bedding!",
            textPart2: "Now, “nickname”, whether you own any farm animals, maybe only hamsters of guinea pigs, or if you have a friend that owns any of the above big or small, we´d still like to hear your opinion.",
            textPart4: "Please assess the below points of animal bedding from most to least important in your opinion.",
            sliderSubtext: '1 Not important - 5 Very important',
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            // additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            bgImage: "img/gograss/sheep.jpg",
            bgStyles: 'background: none;',
            classes: {},
            component: markRaw(ScreenQuestionMultiSlider),
        },
        [ScreensEnum.AnimalBeddingThankYou]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.AnimalBeddingThankYou,
            screenNext: ScreensEnum.GrassBiocharPuzzle,
            title: "@@Awesome {name}, let us suggest another grass product to learn about!.",
            answerKey: ScreensEnum.AnimalBeddingThankYou,
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                grassprotein: "Grass protein",
                roadsidegrass: "Sure, I'd like to know more about paper made from roadside grass.",
                grassbiochar: "Tell me more about the grass-based biochar.",
                allgood: "All good, I think I learned enough for today.",
            },
            markIfAnswerExists: {
                grassprotein: ScreensEnum.GrassProteinThankYou,
                roadsidegrass: ScreensEnum.RoadsideGrassThankYou,
                animalbedding: ScreensEnum.AnimalBeddingThankYou,
                grassbiochar: ScreensEnum.BiocharThankYou,
            },
            optionsFlow: {
                grassprotein: ScreensEnum.GrassBasedProteinPuzzle,
                animalbedding: ScreensEnum.AnimalBeddingPuzzle,
                grassbiochar: ScreensEnum.GrassBiocharPuzzle,
                allgood: ScreensEnum.EndExperience,
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/grassroadside.png) no-repeat center bottom; background-size: 100%;',
        },

        // BIOCHAR
        [ScreensEnum.GrassBiocharPuzzle]: {
            screenId: ScreensEnum.GrassBiocharPuzzle,
            screenNext: ScreensEnum.WhereDoYouGetVegetables,
            answerKey: 'GrassBiocharPuzzle',
            component: markRaw(ScreenSliderPuzzle),
            title: "Welcome {name}! Solve the puzzle and find out what the intention behind using grass-based biochar is.",
            popupTexts: [
                "Grass biochar, as a soil enhancer derived from organic materials, offers a myriad of benefits due to its high carbon consistency and sponge-like properties. Acting as a valuable soil amendment, grass biochar positively influences soil structure and microbiome dynamics, contributing to overall soil health and plant vitality.",
            ],
            image1: "/img/gograss/biocharpuzzle.jpg",
            bgImage: "url('img/gograss/tagbiochar.png')",
            bgStyles: 'background-position: center top; background-size: 60%;',
        },
        [ScreensEnum.WhereDoYouGetVegetables]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.WhereDoYouGetVegetables,
            screenNext: ScreensEnum.BiocharGrowingOpinions,
            title: '@@Now we\'d like to hear from you again. Where do you get your fresh vegetables or flowers?',
            answerKey: 'WhereDoYouGetVegetables',
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                iamfarmer: 'I am a farmer so I grow them.',
                ihavegarden: 'I have a garden and grow them.',
                onbalcony: 'I grow them on my balcony or terrace.',
                localmarket: 'I buy them from a local farmer´s market.',
                atstore: 'I buy them at the store.'
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'p-12',
                rightSide: 'bg-white',
            },
            bgImage: '/img/gograss/vegetables.jpg',
            bgStyles: 'background: none; padding: 0',
            // bgStyles: 'height: 100%; padding: 0; background: url() no-repeat center center; background-size: contain;',
        },
        [ScreensEnum.BiocharGrowingOpinions]: {
            screenId: ScreensEnum.BiocharGrowingOpinions,
            screenNext: ScreensEnum.BiocharThankYou,
            title: '',
            questions: {
                'Better for my health': 'Better for my health',
                'More environmentally friendly': 'More environmentally friendly',
                'improvedquality': 'Improved quality and taste (if edible) of the produce',
                Affordable: 'Affordable',
                'importantforsoil': 'I would know it is grown in a way that improves the quality of soil.',
                lessemissions: 'Causes less emission from composting',
                'Locally grown': 'Locally grown',
            },
            answerKey: 'BiocharGrowingOpinions',
            answerUnit: '',
            additionalOptionsText: 'Other',
            additionalOptionsPlaceholder: 'Please type your answer',
            textPart1: "As we know, eating fresh produce is very important for our health, but do you ever think about how the farming practices used to produce the vegetables and the flowers impact the quality of the soil and the environment. ",
            textPart2: "If you had the option to grow or buy produce that was grown using grass based biochar (instead of chemical based options) what would be most important to you?",
            sliderSubtext: '1 Not important - 5 Very important',
            answerMin: 1,
            answerMax: 5,
            answerStep: 1,
            // additionalOptionsText: 'ShoppingHabits.additionalOptionsText',
            bgImage: "img/gograss/food.png",
            bgStyles: 'background: none;',
            classes: {},
            component: markRaw(ScreenQuestionMultiSlider),
        },
        [ScreensEnum.BiocharThankYou]: {
            component: markRaw(ScreenQuestionButtonsBP),
            screenId: ScreensEnum.BiocharThankYou,
            screenNext: ScreensEnum.EndExperience,
            title: "@@Thank you for sharing your valuable opinion {name}.",
            textPart1: "Since we are progressing so well, we´d like to invite you to also learn about the other interesting grass products!",
            answerKey: ScreensEnum.BiocharThankYou,
            // source: {
            //     text: 'For more on EU Land Cover Statistics, see EUROSTAT, 2021',
            //     link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Land_cover_statistics'
            // },
            options: {
                grassprotein: "Grass protein",
                roadsidegrass: "Sure, I'd like to know more about paper made from roadside grass.",
                animalbedding: 'Ok, I am interested in the animal bedding.',
                allgood: "All good, I think I learned enough for today.",
            },
            markIfAnswerExists: {
                grassprotein: ScreensEnum.GrassProteinThankYou,
                roadsidegrass: ScreensEnum.RoadsideGrassThankYou,
                animalbedding: ScreensEnum.AnimalBeddingThankYou,
                grassbiochar: ScreensEnum.BiocharThankYou,
            },
            optionsFlow: {
                grassprotein: ScreensEnum.GrassBasedProteinPuzzle,
                animalbedding: ScreensEnum.AnimalBeddingPuzzle,
                roadsidegrass: ScreensEnum.RoadsideGrassPuzzle,
                allgood: ScreensEnum.EndExperience,
            },
            classes: {
                wrapperClasses: 'p-0-important',
                bgImageClasses: 'hidden',
            },
            bgImage: '#',
            bgStyles: 'height: 100%; padding: 0; background: url(/img/gograss/biochargrass.png) no-repeat center bottom; background-size: 100%;',
        },

        [ScreensEnum.EndExperience]: {
            screenId: ScreensEnum.EndExperience,
            screenNext: ScreensEnum.GOGRASS_INTRO,
            answerKey: 'EndExperience',
            component: markRaw(ScreenEndGG),
            title: '',
            textPart1: "Dear {name} thank you for going on this virtual journey with us! We hope you enjoyed it and are now richer in knowledge, we know we are!",
            textPart2: "Your answers will greatly help our research and enable us to better understand your expectations and needs as a consumer.",
            textPart3: "If you´d like to read more about the GO-GRASS project and it´s developments you are welcome to visit the website and stay in touch with the partners as they help make our future more friendly for all.",
            textPart4: "In case you'd like to get familiarized also with other topics on intriguing new developments, we have a few available only a click away!",

            buttons: {
                'https://www.prospex-institute.org/pi-elab': 'Ok, I am intrigued, show me the other topic!',
                '/': 'I\'ll be back another day',
            },

            completedSteps: [
                ScreensEnum.GrassProteinThankYou,
                ScreensEnum.RoadsideGrassThankYou,
                ScreensEnum.AnimalBeddingThankYou,
                ScreensEnum.BiocharThankYou,
            ],
            popupTexts: [
                "You successfully completed all of our questions!\nThis will immensely help our research and future development\nof the grass-based products!"
            ],

            // image1: "/img/gograss/products.png",
            // bgImage: "url('img/gograss/grass2.png')",
            // bgStyles: 'background-position: center bottom; background-size: 90%;',
            classes: {
                wrapperClasses: 'font-light bg-image-end',
                buttonsClasses: 'bg-gradient-to-b from-transparent to-white mb-2',
            },
        },


    } as ScreenDefinitions
}
