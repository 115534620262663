
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import PuzzleBoard from '@/components/elements/PuzzleBoard.vue'
import {useState} from "@/hooks/state";
import {ScreenTrueFalse} from "@/Screens.types";
import ActionButton from "@/components/elements/ActionButton.vue";

const state = useState();
export default defineComponent({
  components: {
    ActionButton,
    ScreenWrapper,
    PuzzleBoard
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenTrueFalse,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showNicknameModal: false,
      showQuestion: false,
      showModal: false,
      showNumbers: true,
      difficulty: 'Easy',
      autoResize: true,
      width: 300,
      height: 300,
      animation: true,
      image: 'https://picsum.photos/300'
    }
  },

  mounted() {
    // setTimeout(() => {
    // this.$root.setupAndPlayIfOn(this.FILES.audio_welcome)
    // }, 1000)
  },

  computed: {},

  methods: {
    next(where) {
      if (!this.showModal) {
        this.showModal = true
      } else {
        this.goTo(where || this.config.screenNext)
      }
    },
    back() {
      this.goTo(-1)
    },

    answer(ans: string) {
      if (ans !== 'skip') {
        state.setAnswer(this.config.answerKey, ans, {
          [this.config.answerKey]: this.$t(this.config.textPart1 ?? this.config.title)
        });
      }

      this.next(this.config.screenNext);
    }
  }
});
