
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenConfigIntro} from "@/Screens.types";
import BackNextButtons from "@/components/elements/BackNextButtons.vue";

const state = useState();
export default defineComponent({
  components: {
    BackNextButtons,
    ScreenWrapper
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenConfigIntro,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      showModal: false,
      selectedAreas: {},
      imageMaps: [],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.updateImageMap();
    });

    window.addEventListener('resize', this.updateImageMap);
  },

  computed: {
    imageMapOverlays() {
      return this.imageMaps.map((maps, index) => {
        return maps.split(',').map((coord, index) => {
          return parseInt(coord);
        });
      }).filter((coords) => coords);
    }
  },

  watch: {},

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },

    imageMapSelect(id: string | number) {
      this.selectedAreas[id] = !this.selectedAreas[id];

      this.state.answers[this.config.answerKey] = Object.keys(this.selectedAreas).filter((selected) => this.selectedAreas[selected]).join(',');

      // when 3 selected, open modal
      if (Object.values(this.selectedAreas).filter((selected) => selected).length > 2) {
        this.showModal = true;
      }
    },

    updateImageMap() {
      if (!this.$refs.imageMapImage?.complete) {
        this.imageMaps = [];
        return;
      }
      // resize coordinates based on imageMapImage ref actual vs browsers size
      const xFactor = this.$refs.imageMapImage.clientWidth / this.$refs.imageMapImage.naturalWidth;
      const yFactor = this.$refs.imageMapImage.clientHeight / this.$refs.imageMapImage.naturalHeight;

      this.imageMaps = this.config.imageMap.map((coords) => {
        return coords.map((coord, index) => {
          if (index % 2 === 0) {
            return Math.round(coord * xFactor);
          } else {
            return Math.round(coord * yFactor);
          }
        }).join(',');
      });
    },
  }
});
