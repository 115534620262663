
import {defineComponent} from 'vue';
import ScreenWrapper from "@/components/elements/ScreenWrapper.vue";
import {useState} from "@/hooks/state";
import {ScreenSliders} from "@/Screens.types";
import Slider from "@/components/elements/Slider.vue";

const state = useState();
export default defineComponent({
  components: {
    Slider,
    ScreenWrapper,
  },
  props: {
    msg: String,
    goTo: Function,
    config: Object as () => ScreenSliders,
  },

  setup() {
    return {
      state
    }
  },
  data() {
    return {
      screen: this.config.screenId,
      firstQuestion: {key: '', question: ''},
      otherQuestions: {},
      additional: ''
    }
  },

  mounted() {
    const keys = Object.keys(this.config.questions);
    const firstKey = keys.shift();

    this.firstQuestion = {key: firstKey, question: this.config.questions[firstKey]};

    this.otherQuestions = keys.map(key => ({key, question: this.config.questions[key]}));

    this.additional = this.state.answers.value?.[`${this.config.answerKey}.additional`] ?? '';
  },

  methods: {
    next() {
      this.goTo(this.config.screenNext)
    },
    goToWithSave(screen) {
      if (this.additional) {
        // state.setAnswer(`${this.config.answerKey}.additional`, this.additional);
        this.answer('additional', this.additional, {
          additional: this.$t(this.config.additionalOptionsText)
        });
      }

      for (const key of Object.keys(this.config.questions)) {
        let answer = state.answers.value?.[`${this.config.answerKey}.${key}`];
        if (!answer) {
          // middle value
          if (this.config.answerOptions?.length > 0) {
            answer = this.config.answerOptions[Math.ceil(this.config.answerOptions.length / 2)].id;
          } else {
            // median step between min and max, with step
            answer = Math.ceil((this.config.answerMin + this.config.answerMax) / 2 / this.config.answerStep) * this.config.answerStep;
          }

          this.answer(key, answer, {
            [`${this.config.answerKey}.${key}`]: this.$t(this.config.questions[key])
          });
        }
      }

      this.goTo(screen);
    },


    answer(key, answer: string | number, texts: object) {
      state.setAnswer(`${this.config.answerKey}.${key}`, answer, {
        ...texts
      });
      // this.next();
    }
  }
});
